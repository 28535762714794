import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { User } from '../types/General';
// Interface for the authentication state
export interface AuthState {
  user: User | null;
  token: string | null;
  tempToken: string | null;
  permissions?: string[] | null;
  roleName: string | null;
  pulseToken: string | null;
  pulseTokenTimeStamp: string | null;
}
// Initial state for the authentication
const initialState: AuthState = {
  user: null,
  token: null,
  tempToken: null,
  permissions: null,
  roleName: null,
  pulseToken: null,
  pulseTokenTimeStamp: null,
};
// Create a slice for the authentication state
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to reset the authentication state
    resetAuth: (state) => {
      state.user = null;
      state.token = null;
    },
    // Action to set user and token credentials
    setCredentials: (
      state,
      action: PayloadAction<Pick<AuthState, 'user' | 'token'>>,
    ) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setUser: (state, action: PayloadAction<Pick<AuthState, 'user'>>) => {
      state.user = action.payload.user;
    },
    // Action to set a temporary token
    temporaryToken: (
      state,
      action: PayloadAction<Pick<AuthState, 'tempToken'>>,
    ) => {
      state.tempToken = action.payload.tempToken;
    },
    setToken: (state, action: PayloadAction<Pick<AuthState, 'token'>>) => {
      state.token = action.payload.token;
    },
    // Action to set user permissions
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload ? action.payload : null;
    },
    // Action to set pulse token
    setPulseDetails: (
      state,
      action: PayloadAction<{
        token: string;
        timeStamp: string;
      }>,
    ) => {
      state.pulseToken = action.payload.token;
      state.pulseTokenTimeStamp = action.payload.timeStamp;
    },
    // Action to set the user's role
    role: (state, action: PayloadAction<Pick<AuthState, 'roleName'>>) => {
      state.roleName = action.payload.roleName;
    },
  },
});
export const {
  resetAuth,
  setCredentials,
  temporaryToken,
  setPermissions,
  setPulseDetails,
  role,
  setUser,
  setToken,
} = authSlice.actions;

export const getCurrentUser = (state: RootState) => state.auth.user;
export const getPermissions = (state: RootState) => state.auth.permissions;
export const getToken = (state: RootState) => state.auth.token;
export const getTempToken = (state: RootState) => state.auth.tempToken;
export const getRole = (state: RootState) => state.auth.roleName;
export const getPulseToken = (state: RootState) => state.auth.pulseToken;
export const getPulseTokenTimeStamp = (state: RootState) =>
  state.auth.pulseTokenTimeStamp;

export default authSlice.reducer;
